export default {
  methods: {

    stripeInvoiceStatus(status) {
      switch (status) {
        case 'draft':
          return 'En cours de création'
        case 'open':
          return 'A payer'
        case 'paid':
          return 'Payée'
        case 'void':
          return 'Sans objet'
        case 'uncollectible':
          return 'Sans objet'
      }
      return 'Erreur'
    },
  }
}
