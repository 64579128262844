import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/pages/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/pages/login');
     return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/pages/login')
             return Promise.reject(error)
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/pages/login')
           return Promise.reject(error)
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APIBossConnected {
  // ------------------------ Handle Users -----------------

  getCompany(token) {
    const url = apiBaseUrl + '/company'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
      }
    )
  }

  modifyCompany (token, company_name, siret, forme_sociale, precisions_reglementaires,
    capital, address, phone_country_code, phone_number, iban, bic, titulaire) {
    const url = apiBaseUrl + '/company'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }

    if (capital == 0 || capital == '' || capital == null) {
      capital = null
    }
    return axios_instance.put(
      url,
      {
        'company_name': company_name,
        'siret': siret,
        'forme_sociale': forme_sociale,
        'precisions_reglementaires': precisions_reglementaires,
        'capital': capital,
        'address': address,
        'phone_country_code': phone_country_code,
        'phone_number': phone_number,
        'iban': iban,
        'bic': bic,
        'titulaire': titulaire,
      },
      {
        'headers': headers,
      }
    )
  }

  modifyLogoCompany (token, logo) {
    const url = apiBaseUrl + '/modify-logo-company'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    var formData = new FormData()
    formData.append('logo', logo);
    return axios_instance.put(
      url, formData,
      {
        'headers': headers,
      }
    )
  }


  // ------------------ SUBSCRIPTION FUNCTIONS --------------------
  createSubscription(token, customer_id, payment_method_id, price_id) {
    const url = apiBaseUrl + '/create-subscription'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'customer_id': customer_id,
      'payment_method_id': payment_method_id,
      'price_id': price_id
    }
    return axios_instance.post(
      url, data, {'headers': headers}
    )
  }

  createSubscriptionWithTrial(token, customer_id, price_id) {
    const url = apiBaseUrl + '/create-subscription-with-trial-period'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'customer_id': customer_id,
      'price_id': price_id
    }
    return axios_instance.post(
      url, data, {'headers': headers}
    )
  }

  updateSubscriptionWithFreeTrial(token, payment_method_id) {
    const url = apiBaseUrl + '/update-subscription-with-trial-period'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'payment_method_id': payment_method_id,
    }
    return axios_instance.post(
      url, data, {'headers': headers}
    )
  }

  retrySubscription(token, customer_id, payment_method_id, invoice_id) {
    const url = apiBaseUrl + '/retry-subscription'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }

    var data = {
      'customer_id': customer_id,
      'payment_method_id': payment_method_id,
      'invoice_id': invoice_id
    }
    return axios_instance.post(
      url, data, {'headers': headers}
    )
  }

  completeSubscription(token, subscription_status, subscription_id, subscription_product_id,
    subscription_price_id, subscription_payment_method_id, subscription_current_period_end) {

    const url = apiBaseUrl + '/complete-subscription'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }

    var data = {
      'subscription_status': subscription_status,
      'subscription_id': subscription_id,
      'subscription_product_id': subscription_product_id,
      'subscription_price_id': subscription_price_id,
      'subscription_payment_method_id': subscription_payment_method_id,
      'subscription_current_period_end': subscription_current_period_end
    }
    return axios_instance.post(
      url, data, {'headers': headers}
    )
  }

  cancelSubscription(token, subscription_id) {

    const url = apiBaseUrl + '/cancel-subscription'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'subscription_id': subscription_id,
    }
    return axios_instance.post(
      url, data, {'headers': headers}
    )
  }


  // ------------ PAYMENT METHOD -----------------
  listPaymentMethod(token) {
    const url = apiBaseUrl + '/payment-methods-list'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url, {'headers': headers}
    )
  }

  getDefaultPaymentMethod(token) {
    const url = apiBaseUrl + '/get-default-payment-method'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url, {'headers': headers}
    )
  }

  createSetupIntent(token) {
    const url = apiBaseUrl + '/create-setup-intent'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url, {}, {'headers': headers}
    )
  }

  setPaymentMethodAsDefault(token, payment_method_id) {
    const url = apiBaseUrl + '/set-payment-method-as-default'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      payment_method_id: payment_method_id
    }
    return axios_instance.put(
      url, data, {'headers': headers}
    )
  }

  detachPaymentMethod(token, payment_method_id) {
    const url = apiBaseUrl + '/detach-payment-method'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      payment_method_id: payment_method_id
    }
    return axios_instance.put(
      url, data, {'headers': headers}
    )
  }


  // ------------ INVOICES METHOD -----------------
  listInvoices(token) {
    const url = apiBaseUrl + '/invoices-list'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url, {'headers': headers}
    )
  }

  // -------------- CONVENTION PAR DEFAULT -----------

  getDefaultConventionHonoraires(token, type) {
    const url = apiBaseUrl + '/get-default-convention/' + type
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  modifyDefaultConventionHonoraires(token, convention) {
    const url = apiBaseUrl + '/default-convention/' + convention.id
    var data = {
      introduction: convention.introduction,
      preambule: convention.preambule,
      signature: convention.signature,
      footer: convention.footer
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }


  downloadDefaultConventionHonorairesAsPdf (token, convention_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/download-default-convention/' + convention_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }



  // -------------- CONVENTION ARTICLE PAR DEFAULT -----------
  createDefaultConventionHonorairesArticle(token, convention, title) {
    const url = apiBaseUrl + '/create-default-convention-article/' + convention.id
    var data = {
      title: title,
      body: '<span style="background-color: rgb(255, 255, 0);">Ecrivez le contenu de votre article</span>',
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(url, data, {'headers': headers,})
  }

  modifyDefaultConventionHonorairesArticle(token, article) {
    const url = apiBaseUrl + '/default-convention-article/' + article.id
    var data = {
      title: article.title,
      body: article.body,
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  deleteDefaultConventionHonorairesArticle(token, article) {
    const url = apiBaseUrl + '/default-convention-article/' + article.id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(url, {'headers': headers,})
  }

  ascendDefaultConventionHonorairesArticle(token, article_pk) {
    const url = apiBaseUrl + '/ascend-default-convention-article/' + article_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }

  descendDefaultConventionHonorairesArticle(token, article_pk) {
    const url = apiBaseUrl + '/descend-default-convention-article/' + article_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }





  // -------------- CONVENTION ANNEXE PAR DEFAULT -----------
  createDefaultConventionHonorairesAnnexe(token, convention, title) {
    const url = apiBaseUrl + '/create-default-convention-annexe/' + convention.id
    var data = {
      title: title,
      body: '<span style="background-color: rgb(255, 255, 0);">Ecrivez le contenu de votre annexe</span>',
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(url, data, {'headers': headers,})
  }

  modifyDefaultConventionHonorairesAnnexe(token, annexe) {
    const url = apiBaseUrl + '/default-convention-annexe/' + annexe.id
    var data = {
      title: annexe.title,
      body: annexe.body,
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  deleteDefaultConventionHonorairesAnnexe(token, annexe) {
    const url = apiBaseUrl + '/default-convention-annexe/' + annexe.id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(url, {'headers': headers,})
  }

  ascendDefaultConventionHonorairesAnnexe(token, annexe_pk) {
    const url = apiBaseUrl + '/ascend-default-convention-annexe/' + annexe_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }

  descendDefaultConventionHonorairesAnnexe(token, annexe_pk) {
    const url = apiBaseUrl + '/descend-default-convention-annexe/' + annexe_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }



  // ------------- CONNECTED ACCOUNT ---------------
  createConnectedAccount (token) {
    const url = apiBaseUrl + '/create-connected-account'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url, {},
      {
        'headers': headers,
      },
    )
  }

  createConnectedAccountOnboardingLink (token) {
    const url = apiBaseUrl + '/create-connected-account-onboarding-link'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url, {},
      {
        'headers': headers,
      },
    )
  }

  getExpressDashboardLoginLink (token) {
    const url = apiBaseUrl + '/get-express-dashboard-login-link'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
      },
    )
  }
}
