<template>
  <div class="BossInactiveSubscription">

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100 justify-content-center">
        <CCol class="text-center">
          <div class="w-100">
            <div class="clearfix">
              <h1 class="mt-4">❓ Oups, abonnement annulé ou expiré</h1>
              <div class="mt-4" style="font-size: 22px;">
                <p>
                  <strong>💸 Votre abonnement Eclerk est expiré ou a été annulé 💸</strong>
                </p>
                <p>
                  Ceci peut-être dû à un prélèvement mensuel automatique ayant échoué (rejet ou expiration de votre carte bancaire)
                  ou à une annulation de votre part.
                </p>
                <p>
                  Ne vous inquiétez pas, votre compte est conservé et votre plateforme Eclerk
                  pourra être réutilisée à l'identique dès la régularisation de votre situation.
                  Il vous suffit de renouveler votre abonnement (ou de changer d'abonnement si vous le souhaitez) pour retrouver toutes
                  vos données et fonctionnalités :)
                </p>

                <p>
                  <strong>Vous trouverez ci-dessous l'intégralité de vos factures Eclerk</strong>. Vous pouvez directement payer votre facture en ligne (sur le lien de paiement
                  accessible en bas de la facture).
                </p>

                <CDataTable
                  addTableClasses="text-center"
                  :items="invoicesRender"
                  :fields="invoicesRenderFields"
                  :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
                >

                <template #status="{item}">
                  <td v-if="item.status == 'draft'" class="text-secondary">
                    <strong>{{stripeInvoiceStatus(item.status)}}</strong>
                  </td>
                  <td v-if="item.status == 'open'" class="text-danger">
                    <strong><CIcon name="cil-x-circle" /> {{stripeInvoiceStatus(item.status)}}</strong>
                  </td>
                  <td v-if="item.status == 'paid'" class="text-success">
                    <strong> <CIcon name="cil-check-circle" /> {{stripeInvoiceStatus(item.status)}}</strong>
                  </td>
                  <td v-if="item.status == 'void'" class="text-secondary">
                    <strong>{{stripeInvoiceStatus(item.status)}}</strong>
                  </td>
                  <td v-if="item.status == 'uncollectible'" class="text-secondary">
                    <strong>{{stripeInvoiceStatus(item.status)}}</strong>
                  </td>

                </template>


                <template #amount_due="{item}">
                  <td>
                    <span>{{formatThousandSeparatorNumber(parseFloat(item.amount_due/10).toFixed(2))}} €</span>
                  </td>
                </template>

                <template #invoice_pdf="{item}">
                  <td>
                    <a :href="item.invoice_pdf" target="_blank"><CIcon name="cil-cloud-download"/> Télécharger votre facture</a>
                    <div v-if="item.status != 'paid'">
                      <small><strong class="text-danger">Un lien de paiement est accessible en bas de la facture</strong></small>
                    </div>
                  </td>
                </template>

                </CDataTable>

                <CRow class="mt-4">
                  <CCol class="text-center">
                    <CButton
                      color="primary"
                      shape="pill"
                      block class="px-4"
                      size="lg"
                      @click="$router.push('/abonnement/creation')">
                      Renouveler mon abonnement
                    </CButton>
                  </CCol>
                </CRow>

                <CRow class="mt-4">
                  <CCol class="text-center">
                    <CButton block shape="pill" color="outline-primary" @click="logout"> Me déconnecter </CButton>
                  </CCol>
                </CRow>


                <CRow class="mt-4 mb-4">
                  <CCol>
                    N'hésitez pas à nous contacter à l'adresse
                    <a hrefp="mailto:contact@eclerk.io">contact@eclerk.io</a> ou au numéro 01 84 80 98 81
                  </CCol>
                </CRow>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>

    </CContainer>
  </div>
</template>

<script>
import stripeRenderMixins from '@/mixins/stripeRenderMixins'

import renderMixins from '@/mixins/renderMixins'

import { APIBossConnected } from '@/api/APIBossConnected'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiBossConnected = new APIBossConnected()

export default {
  name: 'BossInactiveSubscription',
  components: {
    Loading,
  },
  mixins: [
    stripeRenderMixins,
    renderMixins
  ],
  data: function () {
    return {

      // ---- PAYMENT METHODS ---------
      isInvoicesLoading: false,
      invoices: [],
      invoicesRender: [],
      invoicesRenderFields: [
        { key: "status", label: "Statut", tdClass: 'ui-helper-center', sortable: true},
        { key: "period_end", label: "Date", tdClass: 'ui-helper-center', sortable: true},
        { key: "amount_due", label: "Montant (TTC)", tdClass: 'ui-helper-center'},
        { key: "invoice_pdf", label: "", tdClass: 'ui-helper-center'},
      ],

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isInvoicesLoading) {
        return true
      }
      return false
    },
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.listInvoices()
  },
  watch: {
    invoices: function (newInvoices) {
        if (newInvoices.length == 0) {
          this.invoicesRender = []
        }
        else {
          this.invoicesRender = []

          for (var i = 0; i < newInvoices.length; i++) {
            var temp_data = {
              'status': newInvoices[i].status,
              'period_end': this.$dayjs.unix(newInvoices[i].period_end).format('DD MMM YYYY'),
              'amount_due': parseFloat(newInvoices[i].amount_due)/10,
              'invoice_pdf': newInvoices[i].invoice_pdf
            }

            this.invoicesRender.push(temp_data)
          }
        }
      },
  },
  methods: {

    // ------------- Stripe functions -----------
    listInvoices() {
      this.isInvoicesLoading = true
      return apiBossConnected.listInvoices(this.token)
        // If the card is declined, display an error to the user.
        .then((result) => {
          this.invoices = result.data.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isInvoicesLoading = false
        })
    },

    logout () {
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/pages/login')
      })
    }
  }
}
</script>
